<template>
  <nav class="w-100 bg-primary menubar">
    <ul>
      <li>
        <router-link :to="{ name: 'TeamHome', params: { lang: lang, console: console, teamSlug: slug } }" exact :class="{ 'router-link-active': menuPrimaryTeam === 'home' }">{{$t('home')}}</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'TeamNotices', params: { lang: lang, console: console, teamSlug: slug } }" exact>{{$t('notices')}}</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'TeamBests', params: { lang: lang, console: console, teamSlug: slug } }" exact>{{$t('theBest')}}</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'TeamLastMatches', params: { lang: lang, console: console, teamSlug: slug } }" exact>Fixture</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'TeamAwards', params: { lang: lang, console: console, teamSlug: slug } }" exact>{{ $tc('award', 2) | toCapitalize }}</router-link>
      </li>
    </ul>
    <ul v-if="menuPrimaryTeam === 'home'">
      <li>
        <router-link :to="{ name: 'TeamHome', params: { lang: lang, console: console, teamSlug: slug } }" exact>{{$t('players')}}</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'TeamTournaments', params: { lang: lang, console: console, teamSlug: slug } }" exact>{{$t('tournaments')}}</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'TeamTraining', params: { lang: lang, console: console, teamSlug: slug } }" exact>{{$t('training')}}</router-link>
      </li>
    </ul>
    <ul v-if="menuPrimaryTeam === 'bests'">
      <li>
        <router-link :to="{ name: 'TeamBests', params: { lang: lang, console: console, teamSlug: slug } }" exact>{{$t('theBest')}}</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'TeamScorers', params: { lang: lang, console: console, teamSlug: slug } }" exact>{{$tc('scorer', 2)}}</router-link>
      </li>
    </ul>
    <ul v-if="menuPrimaryTeam === 'fixture'">
      <li>
        <router-link :to="{ name: 'TeamLastMatches', params: { lang: lang, console: console, teamSlug: slug } }" exact>{{$t('lastMatches')}}</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'TeamFixture', params: { lang: lang, console: console, teamSlug: slug } }" exact>Fixture</router-link>
      </li>
    </ul>
  </nav>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      slug: '*'
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console',
      'menuPrimaryTeam',
      'divisionFront'
    ])
  },
  mounted() {
    this.slug = this.$route.params.teamSlug
  }
}
</script>
