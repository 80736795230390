<template>
  <div class="w-100">
    <div class="container-fluid">
      <div class="row header-section" v-if="teamFront">
        <img v-if="teamFront.bg" slot="img" class="img-fluid header-section-bg" :src="teamFront.bg.image" :srcset="`${teamFront.bg.image_sm} 600w, ${teamFront.bg.image_md} 1024w, ${teamFront.bg.image} 2000w`">
        <div class="col-3 col-sm-3 col-md-2 col-lg-1">
          <img :src="teamFront.image" :alt="teamFront.name" class="img-fluid">
        </div>
        <div class="col-9 col-sm-9 col-md-10 col-lg-11">
          <h1 class="title">{{teamFront.name}}</h1>
          <h6>ID # {{ teamFront.id }}</h6>
          <router-link v-for="(dt, ind) in teamFront.dts" :key="ind" :to="{ name: 'PlayerHome', params: { lang: lang, console: console, playerSlug: dt.slug } }" class="d-block">
            <span>{{ dt.position }}: </span>
            <span>{{ dt.nick }}</span>
          </router-link>
        </div>
        <div class="col-12 justify-content-center py-3">
          <div class="row justify-content-center text-center">
            <div class="col-3 col-sm-4 col-md-2">
              <h6>RANKING</h6>
              <h6>{{ teamFront.position_ranking }}</h6>
            </div>
            <!-- <div class="col-3 col-sm-4 col-md-2 hidden-in-sm">
              <h6>{{ $t('console') }}</h6>
              <img v-if="teamFront.console === 'PSN'" src="../../../assets/images/psn-icon.png" alt="Consola" class="img-icon">
              <img v-if="teamFront.console === 'XBOX'" src="../../../assets/images/xbox-icon.png" alt="Consola" class="img-icon">
              <img v-if="teamFront.console === 'PC'" src="../../../assets/images/pc-icon.png" alt="Consola" class="img-icon">
            </div> -->
            <div class="col-3 col-sm-4 col-md-2">
              <h6>{{ $tc('point', 2) | toCapitalize }}</h6>
              <h6>{{ teamFront.pts }}</h6>
            </div>
            <!-- <div class="col-3 col-sm-4 col-md-2 hidden-in-sm">
              <h6>{{ $t('country') }}</h6>
              <img :src="teamFront.country_flag" alt="País" class="img-icon">
            </div> -->
          </div>
        </div>
        <div class="btns-floats">
          <div class="mb-2">
            <b-button :to="{ name: 'Donations', params: { lang: lang } }" variant="outline-primary" class="btn-icon">
              <img src="../../../assets/images/champions.png" alt="Campeones">
            </b-button>
            <b-button :to="{ name: 'Donations', params: { lang: lang } }" variant="outline-primary" class="btn-icon">
              <img src="../../../assets/images/donate.png" alt="Donaciones">
            </b-button>
            <b-button :disabled="!isAuthenticated" variant="outline-primary" @click="addToFavourites()" class="btn-icon">
              <span class="mr-2">{{ teamFront.favourites}}</span>
              <i class="fa fa-star t-yellow"></i>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <menu-team />
    <div class="col-12">
      <router-view />
    </div>
    <!-- Carrusel de usuarios en linea -->
    <carousel-users-online :show-name="false" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import MenuTeam from  '../partials/MenuTeam'
import CarouselUsersOnline from '../../partials/CarouselUsersOnline.vue'

export default {
  components: {
    MenuTeam,
    CarouselUsersOnline
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'console',
      'teamFront',
      'isAuthenticated'
    ])
  },
  mounted () {
    if (!this.teamFront.tournament) {
      this.fetchData()
    }
  },
  methods: {
    addToFavourites () {
      const slug = this.$route.params.teamSlug
      const lang = this.$route.params.lang
      const console = this.$route.params.console
      const path = `auth/${lang}/console/${console}/team/${slug}`
      this.$axios.post(path).then(() => {
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
      })
    },
    fetchData () {
      const lang = this.$route.params.lang
      const console = this.$route.params.console
      const slug = this.$route.params.teamSlug
      const path = `${lang}/console/${console}/team/${slug}`
      this.$axios.get(path).then((response) => {
        const data = response.data.data
        this.$store.dispatch('SET_TEAM_FRONT', data)
      })
    }
  }
}
</script>
